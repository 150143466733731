import Permissions from 'src/security/permissions';
import config from 'src/config';
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },
  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },
  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },
  {
    path: '/bp-ingredient',
    loader: () =>
      import(
        'src/view/bpingredient/list/IngredientListPage'
      ),
    permissionRequired: permissions.ingredientRead,
    exact: true,
  },
  {
    path: '/new-ops-ingredients',
    loader: () => import('src/view/inventory/NewOpsIngredients'),
    permissionRequired: permissions.ingredientRead,
    exact: true,
  },
  {
    path: '/draft-bp-ingredient',
    loader: () => import('src/view/draftBPIngredient/list/IngredientListPage'),
    permissionRequired: permissions.ingredientRead,
    exact: true,
  },
  {
    path: '/unitless-ingredient',
    loader: () => import('src/view/unitlessIngredients/index'),
    permissionRequired: permissions.ingredientRead,
    exact: true,
  },
  {
    path: '/ingredient-yields',
    loader: () => import('src/view/ingredientYields/index'),
    permissionRequired: permissions.ingredientRead,
    exact: true,
  },
  {
    path: '/packaging-list',
    loader: () => import('src/view/packagingList/index'),
    permissionRequired: permissions.ingredientRead,
    exact: true,
  },
  {
    path: '/ingredient',
    loader: () =>
      import('src/view/ingredient/list/IngredientListPage'),
    permissionRequired: permissions.ingredientRead,
    exact: true,
  },
  {
    path: '/ingredient/new',
    loader: () =>
      import('src/view/ingredient/form/IngredientFormPage'),
    permissionRequired: permissions.ingredientCreate,
    exact: true,
  },
  {
    path: '/ingredient/importer',
    loader: () =>
      import(
        'src/view/ingredient/importer/IngredientImporterPage'
      ),
    permissionRequired: permissions.ingredientImport,
    exact: true,
  },
  {
    path: '/ingredient/:id/edit',
    loader: () =>
      import('src/view/ingredient/form/IngredientFormPage'),
    permissionRequired: permissions.ingredientEdit,
    exact: true,
  },
  {
    path: '/ingredient/:id',
    loader: () =>
      import('src/view/ingredient/view/IngredientViewPage'),
    permissionRequired: permissions.ingredientRead,
    exact: true,
  },
  {
    path: '/ingredients-units',
    loader: () =>
      import(
        'src/view/ingredientsUnits/list/IngredientsUnitsListPage'
      ),
    permissionRequired: permissions.ingredientsUnitsRead,
    exact: true,
  },
  {
    path: '/ingredients-units/new',
    loader: () =>
      import(
        'src/view/ingredientsUnits/form/IngredientsUnitsFormPage'
      ),
    permissionRequired: permissions.ingredientsUnitsCreate,
    exact: true,
  },
  {
    path: '/ingredients-units/importer',
    loader: () =>
      import(
        'src/view/ingredientsUnits/importer/IngredientsUnitsImporterPage'
      ),
    permissionRequired: permissions.ingredientsUnitsImport,
    exact: true,
  },
  {
    path: '/ingredients-units/:id/edit',
    loader: () =>
      import(
        'src/view/ingredientsUnits/form/IngredientsUnitsFormPage'
      ),
    permissionRequired: permissions.ingredientsUnitsEdit,
    exact: true,
  },
  {
    path: '/ingredients-units/:id',
    loader: () =>
      import(
        'src/view/ingredientsUnits/view/IngredientsUnitsViewPage'
      ),
    permissionRequired: permissions.ingredientsUnitsRead,
    exact: true,
  },

  {
    path: '/category',
    loader: () =>
      import('src/view/category/list/CategoryListPage'),
    permissionRequired: permissions.categoryRead,
    exact: true,
  },
  {
    path: '/category/new',
    loader: () =>
      import('src/view/category/form/CategoryFormPage'),
    permissionRequired: permissions.categoryCreate,
    exact: true,
  },
  {
    path: '/category/importer',
    loader: () =>
      import(
        'src/view/category/importer/CategoryImporterPage'
      ),
    permissionRequired: permissions.categoryImport,
    exact: true,
  },
  {
    path: '/category/:id/edit',
    loader: () =>
      import('src/view/category/form/CategoryFormPage'),
    permissionRequired: permissions.categoryEdit,
    exact: true,
  },
  {
    path: '/category/:id',
    loader: () =>
      import('src/view/category/view/CategoryViewPage'),
    permissionRequired: permissions.categoryRead,
    exact: true,
  },

  {
    path: '/lab-stocks',
    loader: () =>
      import('src/view/labStocks/list/LabStocksListPage'),
    permissionRequired: permissions.labStocksRead,
    exact: true,
  },
  {
    path: '/lab-stocks/new',
    loader: () =>
      import('src/view/labStocks/form/LabStocksFormPage'),
    permissionRequired: permissions.labStocksCreate,
    exact: true,
  },
  {
    path: '/lab-stocks/importer',
    loader: () =>
      import(
        'src/view/labStocks/importer/LabStocksImporterPage'
      ),
    permissionRequired: permissions.labStocksImport,
    exact: true,
  },
  {
    path: '/lab-stocks/:id/edit',
    loader: () =>
      import('src/view/labStocks/form/LabStocksFormPage'),
    permissionRequired: permissions.labStocksEdit,
    exact: true,
  },
  {
    path: '/lab-stocks/:id',
    loader: () =>
      import('src/view/labStocks/view/LabStocksViewPage'),
    permissionRequired: permissions.labStocksRead,
    exact: true,
  },

  {
    path: '/storage-type',
    loader: () =>
      import(
        'src/view/storageType/list/StorageTypeListPage'
      ),
    permissionRequired: permissions.storageTypeRead,
    exact: true,
  },
  {
    path: '/storage-type/new',
    loader: () =>
      import(
        'src/view/storageType/form/StorageTypeFormPage'
      ),
    permissionRequired: permissions.storageTypeCreate,
    exact: true,
  },
  {
    path: '/storage-type/importer',
    loader: () =>
      import(
        'src/view/storageType/importer/StorageTypeImporterPage'
      ),
    permissionRequired: permissions.storageTypeImport,
    exact: true,
  },
  {
    path: '/storage-type/:id/edit',
    loader: () =>
      import(
        'src/view/storageType/form/StorageTypeFormPage'
      ),
    permissionRequired: permissions.storageTypeEdit,
    exact: true,
  },
  {
    path: '/storage-type/:id',
    loader: () =>
      import(
        'src/view/storageType/view/StorageTypeViewPage'
      ),
    permissionRequired: permissions.storageTypeRead,
    exact: true,
  },

  {
    path: '/type',
    loader: () =>
      import(
        'src/view/type/list/StorageTypeListPage'
      ),
    permissionRequired: permissions.storageTypeRead,
    exact: true,
  },
  {
    path: '/type/new',
    loader: () =>
      import(
        'src/view/type/form/StorageTypeFormPage'
      ),
    permissionRequired: permissions.storageTypeCreate,
    exact: true,
  },
  {
    path: '/type/importer',
    loader: () =>
      import(
        'src/view/type/importer/StorageTypeImporterPage'
      ),
    permissionRequired: permissions.storageTypeImport,
    exact: true,
  },
  {
    path: '/type/:id/edit',
    loader: () =>
      import(
        'src/view/type/form/StorageTypeFormPage'
      ),
    permissionRequired: permissions.storageTypeEdit,
    exact: true,
  },
  {
    path: '/type/:id',
    loader: () =>
      import(
        'src/view/type/view/StorageTypeViewPage'
      ),
    permissionRequired: permissions.storageTypeRead,
    exact: true,
  },

  {
    path: '/ingredient-root',
    loader: () =>
      import(
        'src/view/ingredientRoot/list/StorageTypeListPage'
      ),
    permissionRequired: permissions.storageTypeRead,
    exact: true,
  },
  {
    path: '/ingredient-root/new',
    loader: () =>
      import(
        'src/view/ingredientRoot/form/StorageTypeFormPage'
      ),
    permissionRequired: permissions.storageTypeCreate,
    exact: true,
  },
  {
    path: '/ingredient-root/importer',
    loader: () =>
      import(
        'src/view/ingredientRoot/importer/StorageTypeImporterPage'
      ),
    permissionRequired: permissions.storageTypeImport,
    exact: true,
  },
  {
    path: '/ingredient-root/:id/edit',
    loader: () =>
      import(
        'src/view/ingredientRoot/form/StorageTypeFormPage'
      ),
    permissionRequired: permissions.storageTypeEdit,
    exact: true,
  },
  {
    path: '/ingredient-root/:id',
    loader: () =>
      import(
        'src/view/ingredientRoot/view/StorageTypeViewPage'
      ),
    permissionRequired: permissions.storageTypeRead,
    exact: true,
  },

  {
    path: '/bp-code',
    loader: () =>
      import('src/view/bpCode/list/BpCodeListPage'),
    permissionRequired: permissions.bpCodeRead,
    exact: true,
  },
  {
    path: '/bp-code/new',
    loader: () =>
      import('src/view/bpCode/form/BpCodeFormPage'),
    permissionRequired: permissions.bpCodeCreate,
    exact: true,
  },
  {
    path: '/bp-code/importer',
    loader: () =>
      import('src/view/bpCode/importer/BpCodeImporterPage'),
    permissionRequired: permissions.bpCodeImport,
    exact: true,
  },
  {
    path: '/bp-code/:id/edit',
    loader: () =>
      import('src/view/bpCode/form/BpCodeFormPage'),
    permissionRequired: permissions.bpCodeEdit,
    exact: true,
  },
  {
    path: '/bp-code/:id',
    loader: () =>
      import('src/view/bpCode/view/BpCodeViewPage'),
    permissionRequired: permissions.bpCodeRead,
    exact: true,
  },

  {
    path: '/unit',
    loader: () => import('src/view/unit/list/UnitListPage'),
    permissionRequired: permissions.unitRead,
    exact: true,
  },
  {
    path: '/unit/new',
    loader: () => import('src/view/unit/form/UnitFormPage'),
    permissionRequired: permissions.unitCreate,
    exact: true,
  },
  {
    path: '/unit/importer',
    loader: () =>
      import('src/view/unit/importer/UnitImporterPage'),
    permissionRequired: permissions.unitImport,
    exact: true,
  },
  {
    path: '/unit/:id/edit',
    loader: () => import('src/view/unit/form/UnitFormPage'),
    permissionRequired: permissions.unitEdit,
    exact: true,
  },
  {
    path: '/unit/:id',
    loader: () => import('src/view/unit/view/UnitViewPage'),
    permissionRequired: permissions.unitRead,
    exact: true,
  },

  {
    path: '/market-brand',
    loader: () =>
      import(
        'src/view/marketBrand/list/MarketBrandListPage'
      ),
    permissionRequired: permissions.marketBrandRead,
    exact: true,
  },
  {
    path: '/market-brand/new',
    loader: () =>
      import(
        'src/view/marketBrand/form/MarketBrandFormPage'
      ),
    permissionRequired: permissions.marketBrandCreate,
    exact: true,
  },
  {
    path: '/market-brand/importer',
    loader: () =>
      import(
        'src/view/marketBrand/importer/MarketBrandImporterPage'
      ),
    permissionRequired: permissions.marketBrandImport,
    exact: true,
  },
  {
    path: '/market-brand/:id/edit',
    loader: () =>
      import(
        'src/view/marketBrand/form/MarketBrandFormPage'
      ),
    permissionRequired: permissions.marketBrandEdit,
    exact: true,
  },
  {
    path: '/market-brand/:id',
    loader: () =>
      import(
        'src/view/marketBrand/view/MarketBrandViewPage'
      ),
    permissionRequired: permissions.marketBrandRead,
    exact: true,
  },
  {
    path: '/recipe-item',
    loader: () =>
      import('src/view/recipeItem/list/RecipeItemListPage'),
    permissionRequired: permissions.recipeItemRead,
    exact: true,
  },
  {
    path: '/common-batch',
    loader: () => import('src/view/recipeItem/common'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/draft-common-batch',
    loader: () => import('src/view/draftRecipeItem/common'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/recipe-item/new',
    loader: () =>
      import('src/view/recipeItem/form/RecipeItemFormPage'),
    permissionRequired: permissions.recipeItemCreate,
    exact: true,
  },
  {
    path: '/recipe-item/importer',
    loader: () =>
      import(
        'src/view/recipeItem/importer/RecipeItemImporterPage'
      ),
    permissionRequired: permissions.recipeItemImport,
    exact: true,
  },
  {
    path: '/recipe-item/:id/edit',
    loader: () =>
      import('src/view/recipeItem/form/RecipeItemFormPage'),
    permissionRequired: permissions.recipeItemEdit,
    exact: true,
  },
  {
    path: '/recipe-item/:id',
    loader: () =>
      import('src/view/recipeItem/view/RecipeItemViewPage'),
    permissionRequired: permissions.recipeItemRead,
    exact: true,
  },

  {
    path: '/draft-recipe-item',
    loader: () => import('src/view/draftRecipeItem/list/RecipeItemListPage'),
    permissionRequired: permissions.recipeItemRead,
    exact: true,
  },
  
  {
    path: '/draft-line-build',
    loader: () => import('src/view/draftLineBuild/list/LineBuildListPage'),
    permissionRequired: permissions.lineBuildRead,
    exact: true,
  },
  {
    path: '/draft-line-build/new',
    loader: () =>
      import('src/view/draftLineBuild/form/LineBuildFormPage'),
    permissionRequired: permissions.lineBuildCreate,
    exact: true,
  },
  {
    path: '/draft-line-build/importer',
    loader: () =>
      import(
        'src/view/draftLineBuild/importer/LineBuildImporterPage'
      ),
    permissionRequired: permissions.lineBuildImport,
    exact: true,
  },
  {
    path: '/draft-line-build/:id/edit',
    loader: () =>
      import('src/view/draftLineBuild/form/LineBuildFormPage'),
    permissionRequired: permissions.lineBuildEdit,
    exact: true,
  },
  {
    path: '/draft-line-build/:id',
    loader: () =>
      import('src/view/draftLineBuild/view/LineBuildViewPage'),
    permissionRequired: permissions.lineBuildRead,
    exact: true,
  },
  
  {
    path: '/draft-recipe-item/new',
    loader: () =>
      import('src/view/draftRecipeItem/form/RecipeItemFormPage'),
    permissionRequired: permissions.recipeItemCreate,
    exact: true,
  },
  {
    path: '/draft-recipe-item/importer',
    loader: () =>
      import(
        'src/view/draftRecipeItem/importer/RecipeItemImporterPage'
      ),
    permissionRequired: permissions.recipeItemImport,
    exact: true,
  },
  {
    path: '/draft-recipe-item/:id/edit',
    loader: () =>
      import('src/view/draftRecipeItem/form/RecipeItemFormPage'),
    permissionRequired: permissions.recipeItemEdit,
    exact: true,
  },
  {
    path: '/draft-recipe-item/:id',
    loader: () =>
      import('src/view/draftRecipeItem/view/RecipeItemViewPage'),
    permissionRequired: permissions.recipeItemRead,
    exact: true,
  },

  {
    path: '/item',
    loader: () => import('src/view/item/list/ItemListPage'),
    permissionRequired: permissions.itemRead,
    exact: true,
  },
  {
    path: '/item/new',
    loader: () => import('src/view/item/form/ItemFormPage'),
    permissionRequired: permissions.itemCreate,
    exact: true,
  },
  {
    path: '/item/importer',
    loader: () =>
      import('src/view/item/importer/ItemImporterPage'),
    permissionRequired: permissions.itemImport,
    exact: true,
  },
  {
    path: '/item/:id/edit',
    loader: () => import('src/view/item/form/ItemFormPage'),
    permissionRequired: permissions.itemEdit,
    exact: true,
  },
  {
    path: '/item/:id',
    loader: () => import('src/view/item/view/ItemViewPage'),
    permissionRequired: permissions.itemRead,
    exact: true,
  },

  {
    path: '/line-build',
    loader: () =>
      import('src/view/lineBuild/list/LineBuildListPage'),
    permissionRequired: permissions.lineBuildRead,
    exact: true,
  },
  {
    path: '/line-build/new',
    loader: () =>
      import('src/view/lineBuild/form/LineBuildFormPage'),
    permissionRequired: permissions.lineBuildCreate,
    exact: true,
  },
  {
    path: '/line-build/importer',
    loader: () =>
      import(
        'src/view/lineBuild/importer/LineBuildImporterPage'
      ),
    permissionRequired: permissions.lineBuildImport,
    exact: true,
  },
  {
    path: '/line-build/:id/edit',
    loader: () =>
      import('src/view/lineBuild/form/LineBuildFormPage'),
    permissionRequired: permissions.lineBuildEdit,
    exact: true,
  },
  {
    path: '/line-build/:id',
    loader: () =>
      import('src/view/lineBuild/view/LineBuildViewPage'),
    permissionRequired: permissions.lineBuildRead,
    exact: true,
  },
  {
    path: '/ingredient-brands',
    loader: () => import('src/view/ingredientBrands/index'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/conversion-unit',
    loader: () =>
      import(
        'src/view/conversionUnit/list/ConversionUnitListPage'
      ),
    permissionRequired: permissions.conversionUnitRead,
    exact: true,
  },
  {
    path: '/conversion-unit/new',
    loader: () =>
      import(
        'src/view/conversionUnit/form/ConversionUnitFormPage'
      ),
    permissionRequired: permissions.conversionUnitCreate,
    exact: true,
  },
  {
    path: '/conversion-unit/importer',
    loader: () =>
      import(
        'src/view/conversionUnit/importer/ConversionUnitImporterPage'
      ),
    permissionRequired: permissions.conversionUnitImport,
    exact: true,
  },
  {
    path: '/conversion-unit/:id/edit',
    loader: () =>
      import(
        'src/view/conversionUnit/form/ConversionUnitFormPage'
      ),
    permissionRequired: permissions.conversionUnitEdit,
    exact: true,
  },
  {
    path: '/conversion-unit/:id',
    loader: () =>
      import(
        'src/view/conversionUnit/view/ConversionUnitViewPage'
      ),
    permissionRequired: permissions.conversionUnitRead,
    exact: true,
  },
  {
    path: '/market-list',
    loader: () => import('src/view/marketList/index'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/draft-market-list',
    loader: () => import('src/view/draftMarketList/index'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/menu-category',
    loader: () =>
      import(
        'src/view/menuCategory/list/MenuCategoryListPage'
      ),
    permissionRequired: permissions.menuCategoryRead,
    exact: true,
  },
  {
    path: '/menu-category/new',
    loader: () =>
      import(
        'src/view/menuCategory/form/MenuCategoryFormPage'
      ),
    permissionRequired: permissions.menuCategoryCreate,
    exact: true,
  },
  {
    path: '/menu-category/importer',
    loader: () =>
      import(
        'src/view/menuCategory/importer/MenuCategoryImporterPage'
      ),
    permissionRequired: permissions.menuCategoryImport,
    exact: true,
  },
  {
    path: '/menu-category/:id/edit',
    loader: () =>
      import(
        'src/view/menuCategory/form/MenuCategoryFormPage'
      ),
    permissionRequired: permissions.menuCategoryEdit,
    exact: true,
  },
  {
    path: '/menu-category/:id',
    loader: () =>
      import(
        'src/view/menuCategory/view/MenuCategoryViewPage'
      ),
    permissionRequired: permissions.menuCategoryRead,
    exact: true,
  },

  {
    path: '/line-build-addon',
    loader: () =>
      import(
        'src/view/lineBuildAddon/list/LineBuildAddonListPage'
      ),
    permissionRequired: permissions.lineBuildAddonRead,
    exact: true,
  },
  {
    path: '/line-build-addon/new',
    loader: () =>
      import(
        'src/view/lineBuildAddon/form/LineBuildAddonFormPage'
      ),
    permissionRequired: permissions.lineBuildAddonCreate,
    exact: true,
  },
  {
    path: '/line-build-addon/importer',
    loader: () =>
      import(
        'src/view/lineBuildAddon/importer/LineBuildAddonImporterPage'
      ),
    permissionRequired: permissions.lineBuildAddonImport,
    exact: true,
  },
  {
    path: '/line-build-addon/:id/edit',
    loader: () =>
      import(
        'src/view/lineBuildAddon/form/LineBuildAddonFormPage'
      ),
    permissionRequired: permissions.lineBuildAddonEdit,
    exact: true,
  },
  {
    path: '/line-build-addon/:id',
    loader: () =>
      import(
        'src/view/lineBuildAddon/view/LineBuildAddonViewPage'
      ),
    permissionRequired: permissions.lineBuildAddonRead,
    exact: true,
  },

  {
    path: '/line-build-extra',
    loader: () =>
      import(
        'src/view/lineBuildExtra/list/LineBuildExtraListPage'
      ),
    permissionRequired: permissions.lineBuildExtraRead,
    exact: true,
  },
  {
    path: '/line-build-extra/new',
    loader: () =>
      import(
        'src/view/lineBuildExtra/form/LineBuildExtraFormPage'
      ),
    permissionRequired: permissions.lineBuildExtraCreate,
    exact: true,
  },
  {
    path: '/line-build-extra/importer',
    loader: () =>
      import(
        'src/view/lineBuildExtra/importer/LineBuildExtraImporterPage'
      ),
    permissionRequired: permissions.lineBuildExtraImport,
    exact: true,
  },
  {
    path: '/line-build-extra/:id/edit',
    loader: () =>
      import(
        'src/view/lineBuildExtra/form/LineBuildExtraFormPage'
      ),
    permissionRequired: permissions.lineBuildExtraEdit,
    exact: true,
  },
  {
    path: '/line-build-extra/:id',
    loader: () =>
      import(
        'src/view/lineBuildExtra/view/LineBuildExtraViewPage'
      ),
    permissionRequired: permissions.lineBuildExtraRead,
    exact: true,
  },

  {
    path: '/line-build-menu-item-mapping',
    loader: () =>
      import(
        'src/view/lineBuildMenuItemMapping/list/LineBuildMenuItemMappingListPage'
      ),
    permissionRequired: null, 
    exact: true,
  },
  {
    path: '/line-build-menu-item-mapping/new',
    loader: () =>
      import(
        'src/view/lineBuildMenuItemMapping/form/LineBuildMenuItemMappingFormPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/line-build-menu-item-mapping/importer',
    loader: () =>
      import(
        'src/view/lineBuildMenuItemMapping/importer/LineBuildMenuItemMappingImporterPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/line-build-menu-item-mapping/:id/edit',
    loader: () =>
      import(
        'src/view/lineBuildMenuItemMapping/form/LineBuildMenuItemMappingFormPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/line-build-menu-item-mapping/:id',
    loader: () =>
      import(
        'src/view/lineBuildMenuItemMapping/view/LineBuildMenuItemMappingViewPage'
      ),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/cutting',
    loader: () =>
      import('src/view/cutting/list/CuttingListPage'),
    permissionRequired: permissions.cuttingRead,
    exact: true,
  },
  {
    path: '/cutting/new',
    loader: () =>
      import('src/view/cutting/form/CuttingFormPage'),
    permissionRequired: permissions.cuttingCreate,
    exact: true,
  },
  {
    path: '/cutting/importer',
    loader: () =>
      import(
        'src/view/cutting/importer/CuttingImporterPage'
      ),
    permissionRequired: permissions.cuttingImport,
    exact: true,
  },
  {
    path: '/cutting/:id/edit',
    loader: () =>
      import('src/view/cutting/form/CuttingFormPage'),
    permissionRequired: permissions.cuttingEdit,
    exact: true,
  },
  {
    path: '/cutting/:id',
    loader: () =>
      import('src/view/cutting/view/CuttingViewPage'),
    permissionRequired: permissions.cuttingRead,
    exact: true,
  },
  {
    path: '/add-missing-packaging',
    loader: () => import('src/view/addMissingPackaging/index'),
    exact: true,
  },
  {
    path: '/ingredientSearch',
    loader: () =>
      import('src/view/ingredientSearchDashboard/index'),
    exact: true,
  },
  {
    path: '/ingredientConsumption',
    loader: () =>
      import('src/view/ingredientConsumption/index'),
    exact: true,
  },
  {
    path: '/packagingConsumption',
    loader: () =>
      import(
        'src/view/ingredientConsumption/packagingConsumption'
      ),
    exact: true,
  },
  {
    path: '/menu-engineering',
    loader: () =>
      import(
        'src/view/ingredientConsumption/menuEngineering'
      ),
    exact: true,
  },
  {
    path: '/ops-sheet',
    loader: () => import('src/view/opsSheet'),
    exact: true,
  },
  {
    path: '/warehouse-waste',
    loader: () => import('src/view/warehouse/waste/form'),
    exact: true,
  },
  
  {
    path: '/sku-ingredients-op',
    loader: () =>
      import('src/view/skuIngredientsOp/list/SkuIngredientsOpListPage'),
    exact: true,
  },
  {
    path: '/sku-ingredients-op/new',
    loader: () =>
      import('src/view/skuIngredientsOp/form/SkuIngredientsOpFormPage'),
    exact: true,
  },
  {
    path: '/sku-ingredients-op/importer',
    loader: () =>
      import(
        'src/view/skuIngredientsOp/importer/SkuIngredientsOpImporterPage'
      ),
    exact: true,
  },
  {
    path: '/sku-ingredients-op/:id/edit',
    loader: () =>
      import('src/view/skuIngredientsOp/form/SkuIngredientsOpFormPage'),
    exact: true,
  },
  {
    path: '/sku-ingredients-op/:id',
    loader: () =>
      import('src/view/skuIngredientsOp/view/SkuIngredientsOpViewPage'),
    exact: true,
  },
  {
    path: '/brand-menu-category',
    loader: () =>
      import('src/view/brandMenuCategory/list/BrandMenuCategoryListPage'),
    exact: true,
  },
  {
    path: '/brand-menu-category/new',
    loader: () =>
      import('src/view/brandMenuCategory/form/BrandMenuCategoryFormPage'),
    permissionRequired: permissions.brandMenuCategoryCreate,
    exact: true,
  },
  {
    path: '/brand-menu-category/importer',
    loader: () =>
      import(
        'src/view/brandMenuCategory/importer/BrandMenuCategoryImporterPage'
      ),
    permissionRequired: permissions.brandMenuCategoryImport,
    exact: true,
  },
  {
    path: '/brand-menu-category/:id/edit',
    loader: () =>
      import('src/view/brandMenuCategory/form/BrandMenuCategoryFormPage'),
    permissionRequired: permissions.brandMenuCategoryEdit,
    exact: true,
  },
  {
    path: '/brand-menu-category/:id',
    loader: () =>
      import('src/view/brandMenuCategory/view/BrandMenuCategoryViewPage'),
    permissionRequired: permissions.brandMenuCategoryRead,
    exact: true,
  },

  {
    path: '/customer-request',
    loader: () =>
      import('src/view/customerRequest/list/CustomerRequestListPage'),
    permissionRequired: permissions.customerRequestRead,
    exact: true,
  },
  {
    path: '/customer-request/new',
    loader: () =>
      import('src/view/customerRequest/form/CustomerRequestFormPage'),
    permissionRequired: permissions.customerRequestCreate,
    exact: true,
  },
  {
    path: '/customer-request/importer',
    loader: () =>
      import(
        'src/view/customerRequest/importer/CustomerRequestImporterPage'
      ),
    permissionRequired: permissions.customerRequestImport,
    exact: true,
  },
  {
    path: '/customer-request/:id/edit',
    loader: () =>
      import('src/view/customerRequest/form/CustomerRequestFormPage'),
    permissionRequired: permissions.customerRequestEdit,
    exact: true,
  },
  {
    path: '/customer-request/:id',
    loader: () =>
      import('src/view/customerRequest/view/CustomerRequestViewPage'),
    permissionRequired: permissions.customerRequestRead,
    exact: true,
  },

  {
    path: '/brand-menu-item',
    loader: () =>
      import('src/view/brandMenuItem/list/BrandMenuItemListPage'),
    permissionRequired: permissions.brandMenuItemRead,
    exact: true,
  },
  {
    path: '/brand-menu-item/new',
    loader: () =>
      import('src/view/brandMenuItem/form/BrandMenuItemFormPage'),
    permissionRequired: permissions.brandMenuItemCreate,
    exact: true,
  },
  {
    path: '/brand-menu-item/importer',
    loader: () =>
      import(
        'src/view/brandMenuItem/importer/BrandMenuItemImporterPage'
      ),
    permissionRequired: permissions.brandMenuItemImport,
    exact: true,
  },
  {
    path: '/brand-menu-item/:id/edit',
    loader: () =>
      import('src/view/brandMenuItem/form/BrandMenuItemFormPage'),
    permissionRequired: permissions.brandMenuItemEdit,
    exact: true,
  },
  {
    path: '/brand-menu-item/:id',
    loader: () =>
      import('src/view/brandMenuItem/view/BrandMenuItemViewPage'),
    permissionRequired: permissions.brandMenuItemRead,
    exact: true,
  },

  {
    path: '/combo-item',
    loader: () =>
      import('src/view/comboItem/list/ComboItemListPage'),
    permissionRequired: permissions.comboItemRead,
    exact: true,
  },
  {
    path: '/combo-item/new',
    loader: () =>
      import('src/view/comboItem/form/ComboItemFormPage'),
    permissionRequired: permissions.comboItemCreate,
    exact: true,
  },
  {
    path: '/combo-item/importer',
    loader: () =>
      import(
        'src/view/comboItem/importer/ComboItemImporterPage'
      ),
    permissionRequired: permissions.comboItemImport,
    exact: true,
  },
  {
    path: '/combo-item/:id/edit',
    loader: () =>
      import('src/view/comboItem/form/ComboItemFormPage'),
    permissionRequired: permissions.comboItemEdit,
    exact: true,
  },
  {
    path: '/combo-item/:id',
    loader: () =>
      import('src/view/comboItem/view/ComboItemViewPage'),
    permissionRequired: permissions.comboItemRead,
    exact: true,
  },

  {
    path: '/combo',
    loader: () =>
      import('src/view/combo/list/ComboListPage'),
    permissionRequired: permissions.comboRead,
    exact: true,
  },
  {
    path: '/combo/new',
    loader: () =>
      import('src/view/combo/form/ComboFormPage'),
    permissionRequired: permissions.comboCreate,
    exact: true,
  },
  {
    path: '/combo/importer',
    loader: () =>
      import(
        'src/view/combo/importer/ComboImporterPage'
      ),
    permissionRequired: permissions.comboImport,
    exact: true,
  },
  {
    path: '/combo/:id/edit',
    loader: () =>
      import('src/view/combo/form/ComboFormPage'),
    permissionRequired: permissions.comboEdit,
    exact: true,
  },
  {
    path: '/combo/:id',
    loader: () =>
      import('src/view/combo/view/ComboViewPage'),
    permissionRequired: permissions.comboRead,
    exact: true,
  },
  {
    path: '/recipe-replacement', 
    loader: () => import('src/view/recipeReplacement/index'), 
    exact: true,
  },
  {
    path: '/batches-market-list', 
    loader: () => import('src/view/batchesMarketList/index'),
    exact: true, 
  },

  {
    path: '/production-plan',
    loader: () =>
      import('src/view/productionPlan/list/ProductionPlanListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-plan/new',
    loader: () =>
      import('src/view/productionPlan/form/ProductionPlanFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-plan/importer',
    loader: () =>
      import(
        'src/view/productionPlan/importer/ProductionPlanImporterPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-plan/:id/edit',
    loader: () =>
      import('src/view/productionPlan/form/ProductionPlanFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-plan/:id',
    loader: () =>
      import('src/view/productionPlan/view/ProductionPlanViewPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-order',
    loader: () =>
      import('src/view/productionOrder/list/ProductionOrderListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/completed-production-order',
    loader: () => import('src/view/productionOrder/list/CompletedProductionOrderListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-order/new',
    loader: () =>
      import('src/view/productionOrder/form/ProductionOrderFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-order/importer',
    loader: () =>
      import(
        'src/view/productionOrder/importer/ProductionOrderImporterPage'
      ),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-order/:id/edit',
    loader: () =>
      import('src/view/productionOrder/form/ProductionOrderFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-order/:id',
    loader: () =>
      import('src/view/productionOrder/view/ProductionOrderViewPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/production-orders-required-ingredients',
    loader: () => import('src/view/productionOrder/requiredIngredients'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/menu-item/:id',
    loader: () => import('src/view/menuItem/view/MenuItemViewPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/bulk-add-menu-item-costs',
    loader: () => import('src/view/bulkAddMenuItemCosts/index'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/import-completed-production-orders',
    loader: () => import('src/view/productionOrder/bulkImport/index'),
    permissionRequired: null,
    exact: true,
  },

].filter(Boolean);



const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
