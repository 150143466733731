import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'lineBuildName',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.lineBuildName',
    ),
    schema: schemas.string(
      i18n(
        'entities.lineBuildMenuItemMapping.fields.lineBuildName',
      ),
      {},
    ),
  },
  {
    name: 'line_build_id',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.lineBuildId',
    ),
    schema: schemas.string(
      i18n(
        'entities.lineBuildMenuItemMapping.fields.lineBuildId',
      ),
      {},
    ),
  },
  {
    name: 'menuItemName',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.menuItemName',
    ),
    schema: schemas.string(
      i18n(
        'entities.lineBuildMenuItemMapping.fields.menuItemName',
      ),
      {},
    ),
  },
  {
    name: 'menu_item_id',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.menuItemId',
    ),
    schema: schemas.string(
      i18n(
        'entities.lineBuildMenuItemMapping.fields.menuItemId',
      ),
      {},
    ),
  },
];
