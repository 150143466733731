import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

const relationToOne = (field) => {
  return (value) => {
    return (value && value?.name) || null;
  };
};

export default [
  {
    name: 'id',
    label: i18n('entities.ingredient.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.ingredient.fields.name'),
  },
  {
    name: 'arabicName',
    label: i18n('entities.ingredient.fields.arabicName'),
  },
  {
    name: 'sku',
    label: i18n('entities.ingredient.fields.sku'),
  },
  {
    name: 'new_sku',
    label: "New SKU",
  },
  // {
  //   name: 'storageType',
  //   label: i18n('entities.ingredient.fields.storageType'),
  //   render: relationToOne('storageType'),
  // },
  {
    name: 'category',
    label: i18n('entities.ingredient.fields.category'),
    render: relationToOne('category'),
  },
  {
    name: 'marketBrand',
    label: i18n('entities.ingredient.fields.marketBrand'),
    render: relationToOne('marketBrand'),
  },
  {
    name: 'type',
    label: "Type",
    render: relationToOne('type'),
  },
  {
    name: 'conversionFactor',
    label: i18n(
      'entities.ingredient.fields.conversionFactor',
    ),
    render: exporterRenders.decimal(),
  },
  {
    name: "costingUnit", 
    label: "BaseUnit",
  },
  {
    name: 'usageUnit',
    label: i18n('entities.ingredient.fields.usageUnit'),
  },
  {
    name: 'ingredientYield',
    label: i18n(
      'entities.ingredient.fields.ingredientYield',
    ),
  },
  {
    name: 'isSellable',
    label: i18n('entities.ingredient.fields.isSellable'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'cost', 
    label: 'Cost',
    render: exporterRenders.decimal(),
  }
];
