import _ from "lodash"; 

export const checkUserRoles = (currentUser: any, roleChecks: any, exclusiveRoles: any = []) => {
    let currentUserTenants = currentUser?.tenants; 
    let currentUserRoles: any = currentUserTenants?.map((tenant: any) => tenant?.roles);
    currentUserRoles = currentUserRoles?.flat();
    currentUserRoles = _.uniq(currentUserRoles);
    if (exclusiveRoles?.some(role => currentUserRoles?.includes(role))) {
        return false;
    };
    return roleChecks?.some(role => currentUserRoles?.includes(role));
};

export const isCurrentUserAdmin = (currentUser) => {
    return checkUserRoles(
        currentUser, 
        ['admin'], 
        [
            'ownsBrandManager', 
            'ownsBrandAssociate', 
        ],
    );
};

export const isCurrentUserOpsUser = (currentUser: any) => {
    return checkUserRoles(currentUser, ['ops', 'opsManager', 'kitchen']);
};

export const isCurrentUserKitchenUser = (currentUser: any) => {
    return checkUserRoles(currentUser, ['kitchen']);
};
