import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

const relationToManyFn = (recipes, items, field) => {
  return (value) => {
    return (value || [])
      .map((item) => {
        if (field === 'items') {
          if (item?.nameId) {
            return (
              items?.find((it) => it?.id === item?.nameId)
                ?.name +
              `/${item?.itemQty}/${item?.itemUnit}/${item?.preferredBrand}/${item?.itemYield}`
            );
          } else if (item?.batchRecipeId) {
            return (
              recipes?.find(
                (recipe) =>
                  recipe?.id === item?.batchRecipeId,
              )?.name +
              `/${item?.itemQty}/${item?.itemUnit}/${item?.itemYield}`
            );
          }
        } else {
          return item?.prepNote;
        }
      })
      .join(',');
  };
};

export const exporterFieldsFn = (recipes, ingredients) => {
  return [
    {
      name: 'id',
      label: i18n('entities.recipeItem.fields.id'),
    },
    {
      name: 'item',
      label: i18n('entities.recipeItem.fields.item'),
      render: relationToManyFn(
        recipes,
        ingredients,
        'items',
      ),
    },
    {
      name: 'itemPrepNotes',
      label:
        i18n('entities.recipeItem.fields.item') +
        ' Prep Notes',
      render: relationToManyFn(
        recipes,
        ingredients,
        'prepNotes',
      ),
    },
    {
      name: 'recipeQty',
      label: i18n('entities.recipeItem.fields.recipeQty'),
    },
    {
      name: 'recipeUnit',
      label: i18n('entities.recipeItem.fields.recipeUnit'),
    },
    {
      name: 'recipeName',
      label: i18n('entities.recipeItem.fields.recipeName'),
    },
    {
      name: 'new_sku',
      label: "New SKU",
    },
    {
      name: 'methods',
      label: i18n('entities.recipeItem.fields.methods'),
    },
    {
      name: 'images',
      label: i18n('entities.recipeItem.fields.images'),
      render: exporterRenders.filesOrImages(),
    },
    {
      name: 'storage',
      label: i18n('entities.recipeItem.fields.storage'),
    },
    {
      name: 'expiryDate',
      label: i18n('entities.recipeItem.fields.expiryDate'),
    },
    {
      name: 'allergens',
      label: i18n('entities.recipeItem.fields.allergens'),
      render: exporterRenders.stringArray(),
    },
    {
      name: 'arName',
      label: i18n('entities.recipeItem.fields.arName'),
    },
    // {
    //   name: 'weightPerPiece',
    //   label: i18n('entities.recipeItem.fields.weightPerPiece'),
    // },
    // {
    //   name: 'incomplete',
    //   label: i18n('entities.recipeItem.fields.incomplete'),
    //   render: exporterRenders.boolean(),
    // },
    {
      name: 'storageType',
      label: i18n('entities.recipeItem.fields.storageType'),
      render: exporterRenders.relationToOne(),
    },
    {
      name: 'category',
      label: i18n('entities.recipeItem.fields.category'),
      render: exporterRenders.relationToOne(),
    },
    {
      name: 'bpCode',
      label: i18n('entities.recipeItem.fields.bpCode'),
      render: exporterRenders.relationToOne(),
    },
    {
      name: 'unit',
      label: i18n('entities.recipeItem.fields.unit'),
      render: exporterRenders.relationToOne(),
    },
    {
      name: 'createdAt',
      label: i18n('entities.recipeItem.fields.createdAt'),
      render: exporterRenders.datetime(),
    },
    {
      name: 'updatedAt',
      label: i18n('entities.recipeItem.fields.updatedAt'),
      render: exporterRenders.datetime(),
    },
  ];
};

export default [
  {
    name: 'id',
    label: i18n('entities.recipeItem.fields.id'),
  },
  {
    name: 'item',
    label: i18n('entities.recipeItem.fields.item'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recipeQty',
    label: i18n('entities.recipeItem.fields.recipeQty'),
  },
  {
    name: 'recipeUnit',
    label: i18n('entities.recipeItem.fields.recipeUnit'),
  },
  {
    name: 'recipeName',
    label: i18n('entities.recipeItem.fields.recipeName'),
  },
  {
    name: 'new_sku',
    label: "New SKU",
  },
  {
    name: 'methods',
    label: i18n('entities.recipeItem.fields.methods'),
  },
  {
    name: 'images',
    label: i18n('entities.recipeItem.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'storage',
    label: i18n('entities.recipeItem.fields.storage'),
  },
  {
    name: 'expiryDate',
    label: i18n('entities.recipeItem.fields.expiryDate'),
  },
  {
    name: 'allergens',
    label: i18n('entities.recipeItem.fields.allergens'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'arName',
    label: i18n('entities.recipeItem.fields.arName'),
  },
  {
    name: 'storageType',
    label: i18n('entities.recipeItem.fields.storageType'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'category',
    label: i18n('entities.recipeItem.fields.category'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'bpCode',
    label: i18n('entities.recipeItem.fields.bpCode'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'unit',
    label: i18n('entities.recipeItem.fields.unit'),
    render: exporterRenders.relationToOne(),
  },
  // {
  //   name: 'weightPerPiece',
  //   label: i18n('entities.recipeItem.fields.weightPerPiece'),
  // },
  // {
  //   name: 'incomplete',
  //   label: i18n('entities.recipeItem.fields.incomplete'),
  //   render: exporterRenders.boolean(),
  // },
  {
    name: 'createdAt',
    label: i18n('entities.recipeItem.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.recipeItem.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
